import React, { Component, createRef } from "react";
import Leaflet from "leaflet";
import "leaflet-extra-markers";
import styled from "styled-components";
import { connect } from "react-redux";
import { apiAddress } from "../../config/general";
import { updateZoom, recenterEnd } from "../../containers/App/actions";
import { debounce } from "lodash";
import MaskLayer from "./MaskLayer";
import SearchLayer from "./SearchLayer";

import {
  Map,
  CircleMarker,
  TileLayer,
  GeoJSON,
  Popup,
  LayersControl,
  ZoomControl,
  FeatureGroup,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
// import { GoogleLayer } from 'react-leaflet-google';

import { setActivePark, toggleTrackUser } from "../../containers/App/actions";
import { userLocation, centereddOnUser } from "../../containers/User/actions";
import "../../../node_modules/leaflet-geosearch/assets/css/leaflet.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import marker from "../../images/marker-icon.png";
import markerShaddow from "../../images/marker-shadow.png";
import { Zoom } from "@material-ui/core";

delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: marker,
  iconUrl: marker,
  shadowUrl: markerShaddow,
});

const MapContainer = styled.div`
  && {
    height: 100%;
    .map-container {
      height: 100%;
      .extra-marker i {
        font-size: 17px;
      }

      .detailsWraper {
        padding: 1em;
        min-width: 200px;
        .element-image {
          height: 180px;
          background-position: center;
          background-size: cover;
          margin: -1.6em -1.6em 1em;
        }
        span {
          font-weight: bold;
        }
        h3 {
          padding: 0;
          margin: 0;
          font-size: 1.3em;
          color: #4da1ff;
          font-weight: normal;
        }
        p {
          padding: 0;
          margin: 0 0 0.8em 0;
          font-size: 1em;
          color: #4a4a4a;
          font-weight: normal;
        }
        ul {
          padding: 0;
          margin: 0;
          font-size: 1em;
          color: #555;
          li {
            list-style-type: none;
          }
        }
      }
    }
  }
`;

class MapComponent extends Component {
  constructor(props) {
    super(props);
    // this.pointToLayer = this.pointToLayer.bind(this);
    this.onEachFeature = this.onEachFeature.bind(this);
    this.pointToLayerElements = this.pointToLayerElements.bind(this);
    this.onEachElement = this.onEachElement.bind(this);
    this.stopUserLocation = this.stopUserLocation.bind(this);
    this.startUserLocation = this.startUserLocation.bind(this);
    this.updateZoom = debounce(this.updateZoom, 1);

    this._editableFG = null;
    this.editLayers = [];
    this.Interval = "";
    this.state = { zoom: 14, overlay: null };
    this.userMarkerParams = {
      radius: 15,
      fillColor: "#5fba7d",
      color: "#5fba7d",
      weight: 3,
      opacity: 1,
      fillOpacity: 0.8,
      riseOnHover: true,
      autoPan: true,
      keepInView: true,
    };

    this.mapRef = createRef();

    window.selectParcel = (parkId, local, freguesia, element) => {
      props.setActivePark(parkId, local, freguesia, element);
    };

    // provider.search({ query: 'lisboa' }).then(results => {
    //   console.log(results);
    // });
  }

  componentDidUpdate() {
    if (this.props.recenterMap) {
      this.props.recenterEnd();
    }
  }

  componentDidMount() {
    var self = this;
    if (this.Interval === "") this.props.userLocation();

    // this.Interval = setInterval(self.props.userLocation, 10000);
  }

  componentWillUnmount() {
    // if (this.Interval !== '') clearInterval(this.Interval);
    this.setState({ overlay: null });
  }

  updateZoom(e) {
    if (
      (e.zoom !== this.props.zoom.zoom && e.center) ||
      (this.props.zoom.center &&
        e.center &&
        this.props.zoom.center.length > 0 &&
        e.center.length > 0 &&
        e.center[0] !== this.props.zoom.center[0] &&
        e.center[1] !== this.props.zoom.center[1]) ||
      !this.props.zoom
    )
      this.props.updateZoom(e);
  }

  pointToLayerElements(props) {
    const currnCat = props.properties.category || "GENERIC";

    let color = props.properties.selected ? "red" : "orange";
    let icon = "fa-number";
    let shape = "penta";
    let prefix = "fa";
    switch (currnCat.toUpperCase().trim()) {
      case "REGA":
        color = "cyan";
        // icon = "icon-drop icons";
        shape = "circle";
        prefix = "";
        break;
      case "VEGETAÇÃO":
        color = "green";
        // icon = "icon-cloud-download icons";
        shape = "star";
        prefix = "";
        break;
      case "MOBILIÁRIO URBANO":
        color = "orange-dark";

        // icon = "icon-layers icons";
        shape = "penta";
        if (props.properties.label.toUpperCase().trim() === "BEBEDOURO") {
          shape = "circle";
        }
        prefix = "";
        break;
      case "OUTROS":
        color = "violet";
        // icon = "icon-event icons";
        shape = "square";
        prefix = "";
        break;
      default:
        break;
    }

    const markerType = Leaflet.ExtraMarkers.icon({
      icon: icon,
      markerColor: color,
      number:
        props.properties.label &&
        props.properties.label.toUpperCase().substring(0, 1),
      shape: shape,
      prefix: prefix,
    });

    return Leaflet.marker(
      [props.geometry.coordinates[1], props.geometry.coordinates[0]],
      { icon: markerType, draggable: this.props.editElements }
    );
  }

  onEachFeature(feature, layer) {
    // layer.bindTooltip(
    //   `${feature.properties.numero}, ${feature.properties.name}`,
    //   {
    //     // offset: Leaflet.point(-10, -10),
    //     interactive: true,
    //     sticky: false,
    //   }
    // );

    // layer.bindPopup(
    //   `${feature.properties.numero}, ${
    //     feature.properties.name
    //   } <br/> <a onClick="window.selectParcel(
    //     '${feature.properties.numero}','${feature.properties.local}',
    //   '${feature.properties.freguesia}',
    //   '${feature.properties.eid}')">Selecionar esta parcela ${
    //     feature.properties.eid
    //   }</a>`,
    //   {
    //     closeOnClick: false,
    //     closeButton: true,
    //   }
    // );
    // layer.bindTooltip('my tooltip text').openTooltip();
    layer.on({
      click: () => {
        // this.openPopup(feature, layer);
        return typeof this.props.onClickMarker === "function"
          ? this.props.onClickMarker(feature)
          : null;
      },
    });
    layer.setStyle({
      weight: this.props.zoom.zoom > 14 ? 3 : 10,
      fillColor:
        feature.properties && feature.properties.color
          ? feature.properties.color
          : "#a92222",
      color:
        feature.properties && feature.properties.color
          ? feature.properties.color
          : "#a92222",
    });

    return layer;
  }

  onEachElement(feature, layer) {
    let outputHtml = "";
    let isParcel =
      feature.properties.label && feature.properties.label.match(/Parcela/);
    Object.keys(feature.properties).map((curr) => {
      if (
        curr !== "id" &&
        curr !== "typeId" &&
        curr !== "type" &&
        curr !== "origin" &&
        curr !== "label" &&
        curr !== "files" &&
        curr !== "observations"
      ) {
        if (curr === "createdAt" || curr === "implementationDate") {
          outputHtml += `<li><span>${
            curr === "implementationDate" ? "date" : curr
          }</span>: ${feature.properties[curr] &&
            feature.properties[curr].split("T")[0]}<br/></li>`;
        } else {
          outputHtml += `<li><span>${curr}</span>: ${feature.properties[curr]}<br/></li>`;
        }
      }
      return null;
    });

    let template = `<div class='detailsWraper'>`;
    /// image
    if (feature.properties.files && feature.properties.files.length > 0) {
      template += `<div class='element-image' style="background-image:url('${apiAddress}${feature.properties.files[0].path}')" ></div>`;
    }

    /// title
    template += `<h3>${feature.properties.label}</h3><p>${feature.properties.observations}</p>`;

    /// vegetaiton

    if (feature.vegetation) {
      template += `<ul class='element-vegetation'>
      <li><span>categoria:</span> ${feature.vegetation.category}</li>
      <li><span>nome cientifico.:</span> ${feature.vegetation.cientificName}</li>
      <li><span>familia:</span> ${feature.vegetation.family}</li>
      <li><span>nome:</span> ${feature.vegetation.normalName}</li>
      </ul>`;
    }

    /// list

    template += `<ul>${outputHtml}</ul></div>`;

    if (isParcel) {
      template = `<span>${feature.properties.label}</span>`;
    }

    layer.bindTooltip(template, {
      // offset: Leaflet.point(-10, -10),
      interactive: true,
      permanent: isParcel,
      sticky: true,
    });

    // layer.bindTooltip('my tooltip text').openTooltip();
    layer.on({
      click: () => {
        return typeof this.props.onClickMarker === "function"
          ? this.props.onClickMarker(feature, "element")
          : null;
      },
      mouseover: () => {
        // console.log(feature);
      },
    });
    if (layer.feature.geometry.type !== "Point") {
      console.log(layer.feature);
      if (layer.feature.properties.category === "Vegetação") {
        layer.setStyle({
          fillColor:
            feature.properties && feature.properties.color
              ? feature.properties.color
              : "#ffdb4d",
          color:
            feature.properties && feature.properties.color
              ? feature.properties.color
              : "#ffdb4d",
        });
      } else {
        layer.setStyle({
          fillColor:
            feature.properties && feature.properties.color
              ? feature.properties.color
              : "#4da1ff",
          color:
            feature.properties && feature.properties.color
              ? feature.properties.color
              : "#4da1ff",
        });
      }
    }

    return layer;
  }

  stopUserLocation() {
    this.props.toggleTrackUser();
  }

  startUserLocation() {
    this.props.toggleTrackUser();
  }

  getCenter() {
    let lat = "";
    let lng = "";
    let getSelection = [];
    let getLocal = [];
    let getFreguesia = [];

    // keep the normal way

    // this.props.geoJson.map(park => {
    //   if (park.properties.color === '#01f4c1') getSelection.push(park);
    //   if (park.properties.color === '#4da1ff') getLocal.push(park);
    //   if (park.properties.area) getFreguesia.push(park);
    //   return null;
    // });
    if (this.props.centerToUser) {
      lat = this.props.position.lat;
      lng = this.props.position.lng;
      this.props.centereddOnUser();
    } else if (
      this.props.latestSelection.type === "local" &&
      this.props.geoJson.local &&
      this.props.geoJson.local.length > 0
    ) {
      lat = this.props.geoJson.local[0].geometry.coordinates[0][0][0][1];
      lng = this.props.geoJson.local[0].geometry.coordinates[0][0][0][0];
    } else if (
      this.props.latestSelection.type === "freguesia" &&
      this.props.geoJson.freguesia &&
      this.props.geoJson.freguesia.length > 0
    ) {
      lat = this.props.geoJson.freguesia[0].geometry.coordinates[0][0][0][1];
      lng = this.props.geoJson.freguesia[0].geometry.coordinates[0][0][0][0];
    } else if (
      this.props.latestSelection.type === "selected" &&
      this.props.geoJson.selected &&
      this.props.geoJson.selected.length > 0
    ) {
      if (this.props.geoJson.selected.length >= 1) {
        lat = this.props.geoJson.selected[0].geometry.coordinates[0][0][0][1];
        lng = this.props.geoJson.selected[0].geometry.coordinates[0][0][0][0];
      } else {
        lat = this.props.zoom.center[0];
        lng = this.props.zoom.center[1];
      }
    } else if (
      this.props.geoJson &&
      this.props.geoJson.geoJson &&
      this.props.geoJson.geoJson.length > 0 &&
      this.props.centerToUser
    ) {
      lat = this.props.geoJson.geoJson[0].geometry.coordinates[0][0][0][1];
      lng = this.props.geoJson.geoJson[0].geometry.coordinates[0][0][0][0];
    } else if (this.props.zoom.center) {
      lat = this.props.zoom.center[0];
      lng = this.props.zoom.center[1];
    } else if (this.props.position) {
      lat = this.props.position.lat;
      lng = this.props.position.lng;
    }

    return { lat, lng };
  }

  onFeatureGroupReady(reactFGref) {
    // populate the leaflet FeatureGroup with the geoJson layers
    if (reactFGref && this.props.elementActionType === "edit") {
      let leafletGeoJSON = new Leaflet.GeoJSON(this.props.elements);
      let leafletFG = reactFGref.leafletElement;

      // duplication happening removing before adding
      if (reactFGref && this.editLayers.length > 0) {
        this.editLayers.map((lay) => {
          return leafletFG.removeLayer(lay);
        });
        this.editLayers = [];
      }

      // lets add now
      leafletGeoJSON.eachLayer((layer) => {
        layer.on({
          click: () => {
            return typeof this.props.onClickEditMarker === "function"
              ? this.props.onClickEditMarker(layer.feature)
              : null;
          },
          mouseover: () => {
            // console.log(feature);
          },
        });
        this.editLayers.push(layer);
        leafletFG.addLayer(layer);
      });

      // store the ref for future access to content

      this._editableFG = reactFGref;
    }
  }

  renderAddElementsControl() {
    return (
      !this.props.creatingElement && (
        <FeatureGroup>
          <EditControl
            ref="edit"
            position="topleft"
            //this is the necessary function. It goes through each layer
            //and runs my save function on the layer, converted to GeoJSON
            //which is an organic function of leaflet layers.
            onCreated={(e) => {
              const geoJson = e.layer.toGeoJSON();
              const type = e.layerType;
              this.props.onCreated(type, geoJson);
              // Now we need to remove it
              // it will be added from the db later
              // const { edit } = this.refs;
              // edit.context.layerContainer.removeLayer(e.layer._leaflet_id);
            }}
            onEdited={(e) => {
              e.layers.eachLayer((a) => {
                // this.props.updatePlot({
                //   id: '',
                //   feature: a.toGeoJSON(),
                // });
              });
            }}
            edit={{ circle: false, marker: false, remove: false }}
            draw={{
              marker: false,
              circle: false,
              rectangle: false,
              polygon: true,
              polyline: false,
            }}
          />
        </FeatureGroup>
      )
    );
  }

  renderEditElementsControl() {
    return (
      <FeatureGroup
        ref={(reactFGref) => {
          this.onFeatureGroupReady(reactFGref);
        }}
      >
        <EditControl
          ref="edit"
          key={`${new Date()}-elements`}
          position="topleft"
          onEditStart={() => this.stopUserLocation()}
          onEditStop={() => this.startUserLocation()}
          onEdited={async (e) => {
            // console.log(e.layers._layers);

            // Object.keys(thisLayers).map(lay => {
            //   this.props.updateGeojsonElement(
            //     thisLayers[lay].feature.properties.id,
            //     thisLayers[lay].feature.geometry
            //   );
            // });

            await e.layers.eachLayer((a) => {
              // console.log(a);
              const elementJson = a.toGeoJSON();

              this.props.updateGeojsonElement(
                elementJson.properties.id,
                elementJson.geometry
              );
            });

            //we need to update element view
            this.props.getElements();
          }}
          edit={{ circle: false, marker: false, remove: false }}
          draw={{
            marker: false,
            circle: false,
            rectangle: false,
            polygon: false,
            polyline: false,
          }}
        />
      </FeatureGroup>
    );
  }

  render() {
    const center = this.getCenter();
    const centerTo = this.props.zoom;

    // setting defaults
    let mapProps = {
      zoom: centerTo.zoom || 14,
      // zoom: this.props.selected.length > 1 ? 18 : 14,
      center: centerTo.center,
    };

    // we have a center
    if (center && center.lat !== 0 && center.lng !== 0) {
      mapProps.center = [center.lat, center.lng];
      mapProps.panTo = [center.lat, center.lng];
    }

    //fallback
    if (center && center.lat === "" && center.lng === "") {
      mapProps.center = [38.719637, -9.395666];
      mapProps.panTo = [38.719637, -9.395666];
    }

    return (
      <MapContainer>
        <Map
          zoomControl={false}
          className={"map-container"}
          attr={this.props.element && this.props.element.length}
          maxZoom={23}
          ref={this.mapRef}
          // onViewportChanged={(e) => this.updateZoom(e)}
          {...mapProps}
        >
          {this.props.geoJson.geoJson &&
            this.props.geoJsonStatus !== "getting" &&
            this.props.geoJson.geoJson.length >= 1 && (
              <GeoJSON
                key={`${new Date()}-parks`}
                data={this.props.geoJson.geoJson}
                style={{
                  fillColor: "#a92222",
                  color: "#a92222",
                }}
                // pointToLayer={this.pointToLayer}
                onEachFeature={this.props.onEachFeature || this.onEachFeature}
              />
            )}
          {this.props.status !== "elements_received" &&
          this.props.status !== "searchUpdated" &&
          this.props.zoom.zoom >= 10 &&
          !this.props.editElements && //we will add this in a feature group
            this.props.elements &&
            this.props.elements.length >= 1 && (
              <GeoJSON
                key={`${new Date()}-elements`}
                data={this.props.elements}
                // style={this.style}
                pointToLayer={this.pointToLayerElements}
                onEachFeature={this.props.onEachElement || this.onEachElement}
              />
            )}
          {/* <MaskLayer /> */}
          {this.props.elementActionType === "add" &&
            this.props.selected.length === 1 &&
            this.renderAddElementsControl()}
          {this.props.elementActionType === "edit" &&
            // this.props.selected.length === 1 && //this is for added elements contador
            this.renderEditElementsControl()}

          {/* <TileLayer
            maxZoom={22}
            maxNativeZoom={18}
            url="https://geocascais.cascais.pt:8443/raster/ortos2019/{z}/{x}/{y}.jpg"
            attribution="Cascais Ambiente &copy;"
          /> */}
          <TileLayer
            maxZoom={22}
            maxNativeZoom={18}
            url="https://geo1.cascais.pt:8443/raster/ortos2019/{z}/{x}/{y}.jpg "
            attribution="Cascais Ambiente &copy;"
          />
          {/* <TileLayer
            maxZoom={22}
            maxNativeZoom={18}
            url="https://geocascais.cascais.pt/servicehub/geoserver/ogc/gwc/service/wmts?Version=1.0.0&layer=GeoCascais%3Aortos_2023&style=&tilematrixset=EPSG%3A900913&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A900913%3A{z}&TileCol={x}&TileRow={y}"
            attribution="Cascais Ambiente &copy;"
          /> */}

          {this.props.position &&
            this.props.position.lat !== "" &&
            this.props.position.lng !== "" && (
              <CircleMarker
                center={[this.props.position.lat, this.props.position.lng]}
                {...this.userMarkerParams}
              />
            )}
          <ZoomControl position={"topright"} />

          <SearchLayer
            onClickMarker={this.props.onClickMarker}
            geoJsonUpdate={this.props.geoJsonUpdate}
            position={"topright"}
          />
        </Map>
      </MapContainer>
    );
  }
}

export default connect(
  (state) => {
    return {
      position: state.user.position,
      mapType: state.app.mapType,
      recenterMap: state.app.recenterMap,
      centerToUser: state.user.centerToUser,
      status: state.app.status,
      zoom: state.app.zoom,
      selected: state.app.search.selected,
      latestSelection: state.app.search.latestSelection,
      element: state.app.search.element,
      geoJsonUpdate: state.parklist.geoJson,
      geoJsonStatus: state.parklist.status,
    };
  },
  {
    userLocation,
    updateZoom,
    setActivePark,
    toggleTrackUser,
    centereddOnUser,
    recenterEnd,
  }
)(MapComponent);
